import React from "react";
import { Statistic, Progress, Row } from "antd";
import { COPILOTS_NAME } from "../../../utils";
import { APICallsIcon } from "../../../assets/icons/APICallsIcon";
import { SearchStatsIcon } from "../../../assets/icons";

interface ApiCallStatisticsType {
  tokenCount: TokenData;
  copilotName?: string;
  negativeFeedback?: {
    count:number;
    percentage:number;
  };
}

type TokenData = {
  date: string;
  token_count: {
    service_name: string;
    moduleName: string;
    token_count?: number;
    searchCount?: number
  }[];
}[];

type ClaimsModules = "Search Initiated" | "Search Failed" | "Search Success" | "Feedback Accuracy" | "Feedback Count";

interface CopilotTokenCount {
  [moduleName: string]: number;
}

const CLAIMS_COLOR_MAP: Record<ClaimsModules, { color: string; backgroundColor: string }> = {
  "Search Initiated": {
    color: "#7280CD", 
    backgroundColor: "#DDE1F7"
  },
  "Search Failed": {
    color: "#DA7C7C", 
    backgroundColor: "#FFF0EA"
  },
  "Search Success": {
    color: "#5EADA6",
    backgroundColor: "#D1E9E7"
  },
  "Feedback Accuracy": {
    color: "#E2B039",
    backgroundColor: "#F7EBCF"
  },
  "Feedback Count": {
    color: "#FF5733", 
    backgroundColor: "#FFE4E1" 
  }
};

const COLORS = [
  "#DA7C7C",
  "#7280CD",
  "#5EADA6",
  "#E2B039",
  "#DA7C7C",
  "#7280CD",
  "#5EADA6",
  "#E2B039",
];

const BACKGROUND = [
  "#FFF0EA",
  "#DDE1F7",
  "#D1E9E7",
  "#F7EBCF",
  "#FFF0EA",
  "#DDE1F7",
  "#D1E9E7",
  "#F7EBCF",
];

const CopilotStatistic = ({
  title,
  value,
  total,
  color,
  backgroundColor,
  copilot
}: {
  title: string;
  value: number;
  total: number;
  color: string;
  backgroundColor: string;
  copilot?: string;
}) => (
  <div className="flex gp-10 m-t">
    {copilot === COPILOTS_NAME.CLAIMS ? 
      (<SearchStatsIcon fill={color} backGroundFill={backgroundColor} />) 
      : (<APICallsIcon fill={color} backGroundFill={backgroundColor} />)
    }
    <Statistic
      title={title}
      value={value}
      suffix={copilot!==COPILOTS_NAME.CLAIMS && "Calls"}
      valueStyle={{ color: color, fontSize: "20px", fontWeight: "600" }}
    />
  </div>
);

const ApiCallStatistics = ({ tokenCount, copilotName, negativeFeedback }: ApiCallStatisticsType) => {
  const transformData = (tokenData: TokenData) => {
    if (!tokenData || tokenData.length === 0) {
      return {};
    }
    const result: Record<string, Record<string, number>> = {};
    tokenData.forEach(({ token_count }) => {
      const tokenDataArray = token_count; 

      tokenDataArray?.forEach(({ service_name, moduleName, token_count: count, searchCount: searchCnt }) => {
        const actualCount = count ?? searchCnt ?? 0; 

        if (!result[service_name]) {
          result[service_name] = {};
        }

        if (!result[service_name][moduleName]) {
          result[service_name][moduleName] = 0;
        }

        result[service_name][moduleName] += +actualCount; 
      });
    });

    const totalResult: Record<string, number> = {};

    if (copilotName === COPILOTS_NAME.CLAIMS) {
      const order = ['Search Initiated', 'Search Success', 'Search Failed'];
  
      Object.keys(result).forEach((service_name) => {
        const orderedModuleNames = Object.keys(result[service_name]).sort(
          (a, b) => order.indexOf(a) - order.indexOf(b)
        );
  
        orderedModuleNames.forEach((moduleName) => {
          if (!totalResult[moduleName]) {
            totalResult[moduleName] = 0;
          }
  
          totalResult[moduleName] += result[service_name][moduleName];
        });
      });
    } else {
      Object.keys(result).forEach((service_name) => {
        Object.keys(result[service_name]).forEach((moduleName) => {
          if (!totalResult[moduleName]) {
            totalResult[moduleName] = 0;
          }
  
          totalResult[moduleName] += result[service_name][moduleName];
        });
      });
    }

    return totalResult;
  };

  const copilotTokenCount = transformData(tokenCount);
  const claimsCopilotTokenCount = {
    ...copilotTokenCount,
    "Feedback Percentage": negativeFeedback?.percentage ?? 0,
    "Feedback Count": negativeFeedback?.count ?? 0,
  }

  return (
    <div className="statistics">
      <h4 className="summary-title">
        {copilotName === COPILOTS_NAME.CLAIMS ? "Search Statistics Overview" : "API Call Statistics Overview"}
      </h4>
      <p>
        {copilotName === COPILOTS_NAME.CLAIMS ? 
          "A search session will be considered as Started when a user initiates a search process.  A session is deemed Completed when the user visits all options and follows through to claim the action recommendation provided by the system in that specific session." : 
          "Summary description for API call statistics overview"}
      </p>
      <Row className="grid grid-cols-2">
        {copilotTokenCount &&
          Object.entries(copilotName === COPILOTS_NAME.CLAIMS ? claimsCopilotTokenCount : copilotTokenCount).map(([moduleName, count], index) => {
            const { color, backgroundColor } = copilotName === COPILOTS_NAME.CLAIMS && 
              CLAIMS_COLOR_MAP[moduleName as ClaimsModules] 
              ? CLAIMS_COLOR_MAP[moduleName as ClaimsModules] 
              : { color: COLORS[index], backgroundColor: BACKGROUND[index] };

            return (
              <CopilotStatistic
                key={moduleName}
                title={moduleName}
                value={typeof count === 'number' ? count : 0}
                total={Object.values(copilotTokenCount).reduce((acc, val) => acc + val, 0)}
                color={color}
                backgroundColor={backgroundColor}
                copilot={copilotName}
              />
            );
          })}
      </Row>
    </div>
  );
};

export default ApiCallStatistics;
