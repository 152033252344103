import React, { useEffect, useState } from "react";
import { RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import "./graphs.scss";
import { useFetchDocuments } from "../../../hooks";
import { Loading } from "../../../components";
import { Table, Select } from "antd";
import { COPILOTS_NAME } from "../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface ExtractionDataProps {
  copilotName: string;
  overviewFromDate: string;
  toDate: string;
  chartData: {
    successes: number;
    failures: number;
  };
}

const { Option } = Select;

export const ExtractionData = ({
  copilotName,
  overviewFromDate,
  toDate,
  chartData,
}: ExtractionDataProps) => {
  const {
    setFromDate,
    setToDate,
    defaultFromDate,
    defaultToDate,
    searchTerm,
    setSearchTerm,
    fetchExtractionData,
    extractionData,
    isDocumentsLoading,
    hedisExtractionData,
  } = useFetchDocuments(copilotName);


  const [selectedOption, setSelectedOption] = useState<
    "No: of pages extracted" | "No: of documents extracted"
  >("No: of documents extracted");
  const {tempData} = useSelector((state: RootState) => state.ui);
  useEffect(() => {
    fetchExtractionData(
      overviewFromDate,
      toDate,
      selectedOption === "No: of documents extracted" ? "document" : "page"
    );
  }, [overviewFromDate, toDate, selectedOption]);

  const handleSelectChange = (value: string) => {
    setSelectedOption(value as typeof selectedOption);
  };

  const getTitleOption = () => {
    if (copilotName === COPILOTS_NAME.HEDIS_INSIGHTS) {
      return (
        <Select defaultValue={selectedOption} onChange={handleSelectChange}>
          <Option value="No: of pages extracted">No: of pages extracted</Option>
          <Option value="No: of documents extracted">No: of documents extracted</Option>
        </Select>
      );
    } else {
      return <div>No: of documents extracted</div>;
    }
  };

  const columns = [
    {
      title: "Fields",
      dataIndex: "field",
      key: "field",
    },
    {
      title: () => getTitleOption(),
      dataIndex: "count",
      key: "count",
      className: "extractionData-table-count",
    },
  ];

  return (
    <div className="">
      {/* <h3 className="summary-title">Extraction Data</h3> */}
      {copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH && 
        // (!isDocumentsLoading && extractionData && Object.keys(extractionData).length ? (
          <>
            <div className="">
              <h1>{`Total Documents: ${tempData?.totalDocuments || 0}`}</h1>
              {/* <h1>{`Total Documents:// ${chartData.successes + chartData.failures}`}</h1> */}
              {/* <h1>{`Total Fields: ${extractionData.totalFields}`}</h1>
            <h1>{`Total Extracted Fields: ${extractionData.totalExtractedFields}`}</h1>
            <h1>{`Total Missing Fields: ${extractionData.totalMissingFields}`}</h1> */}
              <h1>{`Total SRF Page Found: ${tempData?.srfDocuments}`}</h1>
            </div>
            {/* <Table
              columns={columns}
              dataSource={Object.keys(extractionData?.fieldWiseExtractionData).map((field) => ({
                key: field,
                field,
                count:
                  extractionData?.fieldWiseExtractionData[field as keyof IFieldWiseExtractionData],
              }))}
              pagination={false}
              size="small"
            /> */}
          </>
        // ) : (
        //   <Loading tip={"Loading extraction data..."} />
        // ))
        }
      {copilotName === COPILOTS_NAME.HEDIS_INSIGHTS &&
        (!isDocumentsLoading && Object.keys(hedisExtractionData).length ? (
          <>
            <div className="extractionData-data">
              <h1>{`Total ${
                selectedOption === "No: of documents extracted" ? "Documents" : "Pages"
              }:  ${hedisExtractionData.count || 0}`}</h1>
            </div>
            <Table
              columns={columns}
              dataSource={Object.keys(hedisExtractionData?.keywords).map((field) => ({
                key: field,
                field,
                count: hedisExtractionData?.keywords[field as keyof IFieldWiseExtractionData],
              }))}
              pagination={false}
              size="small"
            />
          </>
        ) : (
          <Loading tip={"Loading extraction data..."} />
        ))}
    </div>
  );
};
