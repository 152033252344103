import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFetchDocuments } from "../../../hooks";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import {
  COPILOTS_NAME,
  getLocalDateTimeString,
} from "../../../utils";
import { AppPagination, NotAccessibleFallback } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import "./copilots-details.scss";
import { useNavigate } from "react-router";
import { Moment } from "moment";
import { DatePicker } from "../../../components";
import { RangeValue } from "rc-picker/lib/interface";
import { Button, Select, Empty } from "antd";
import {
  CaretDownFilled,
  CaretUpFilled,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getClaimsStatusClass, rangePresets } from "./helper";
import { SorterResult } from "antd/es/table/interface";
import { useParams } from "react-router-dom";
import { RootState, setSelectedDateRange } from "../../../store";
import moment from "moment";
import { EvidenceContentJSON } from "../../evidence-viewer/evidence-viewer-container";

const { Option } = Select;

const disableEvidenceViewer = process.env.REACT_APP_HIDE_EVIDENCE_VIEWER === "true";

const { RangePicker } = DatePicker;

export interface ISearchDataType {
    key: string;              
    claimId: string;           
    state: string;             
    userId: string;            
    sessionStatus: string;    
    searchStatus: string;      
    statusDescription: string; 
    createdAt: string;         
    updatedAt: string;        
    status: string;            
    sessionId: string;         
    resJSON: string;           
}

interface Props {
  copilotName: string;
}

const SearchLogs = ({ copilotName }: Props) => {
  const {
    searches,
    isDocumentsLoading,
    setIsDocumentsLoading,
    paginationObj,
    setDocuments,
    setTotalDocuments,
    sortFilter,
    totalDocuments,
    isAuthorized,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    defaultFromDate,
    defaultToDate,
    searchTerm,
    searchDocumentUUID,
    setSearchTerm,
    setSearchDocumentUUID,
    setSortBy,
    setStatus,
    fetchClaimsSearches
  } = useFetchDocuments(copilotName);
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchUuid, setSearchUuid] = useState("");
  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const searchInputRef = useRef(null as any);
  const [sorterState, setSorterState] = useState() as any;
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    { key: string; status: string; sessionId: string }[]
  >([]);
  const { copilotId } = useParams();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );

  const onTableChange: TableProps<ISearchDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    if (searchInputRef.current && document.activeElement === searchInputRef.current.input) {
      return;
    }
    setSorterState(sorter);
    const firstSorter = sorter as SorterResult<ISearchDataType>;
    switch (firstSorter.order) {
      case "descend":
        setSortBy(`-${firstSorter.field}`);
        break;
      case "ascend":
        setSortBy(firstSorter.field as string);
        break;
      default:
        setSortBy("");
        break;
    }
  };

  const handleExpand = (expanded: boolean, record: ISearchDataType) => {
    if (expanded) {
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const handleSearch = (event: any, type?: string) => {
    if (type === "uuid") {
      setSearchDocumentUUID(searchUuid);
    } else {
      setSearchTerm(searchText);
    }
    onPaginationChange(1, pageSize);
  };

  const handleChange = (event: any, type?: string) => {
    if (type === "uuid") {
      setSearchUuid(event.target.value);
      if (event.target.value == "") {
        setSearchDocumentUUID("");
        onPaginationChange(1, pageSize);
      }
    } else {
      setSearchText(event.target.value);
      if (event.target.value == "") {
        setSearchTerm("");
        onPaginationChange(1, pageSize);
      }
    }
  };

  const handleSort = (column: string) => {
    if (!sorterState) {
      setSortBy(`${column}`);
    } else if (sorterState.order === "ascend") {
      setSortBy(`-${column}`);
    } else if (sorterState.order === "descend") {
      setSortBy(``);
    }
  };

  const getSortIcon = (column: string) => {
    let caretUpFilledStyle = { color: "#0000004a" };
    let caretDownFilledStyle = { marginTop: "-0.3em", color: "#0000004a" };
    if (sorterState && sorterState.field === column) {
      if (sorterState.order === "ascend") {
        caretUpFilledStyle = { color: "#000000" };
        caretDownFilledStyle = { marginTop: "-0.3em", color: "#0000004a" };
      } else if (sorterState.order === "descend") {
        caretUpFilledStyle = { color: "#0000004a" };
        caretDownFilledStyle = { marginTop: "-0.3em", color: "#000000" };
      }
    }
    return (
      <>
        <CaretUpFilled style={caretUpFilledStyle} />
        <CaretDownFilled style={caretDownFilledStyle} />
      </>
    );
  };





  const dataSource: ISearchDataType[] = useMemo(
    () =>
        searches.map((document) => ({
          key: document.id.toString(),
          claimId: document.claimId,
          state: document.state,
          userId: document.userId,
          sessionStatus: document.sessionStatus,
          searchStatus: document.searchStatus,
          statusDescription: document.statusDescription,
          createdAt: getLocalDateTimeString(document.createdAt),
          updatedAt: getLocalDateTimeString(document.updatedAt),
          status: document.sessionStatus || document.searchStatus,
          sessionId: document.sessionId,
          resJSON: document.resJSON,
        })),
      [searches]
  );
  

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = dataSource
        .map((item) => ({ key: item.key, status: item.status, sessionId: item.key }))
        .filter((item) => !selectedRowKeys.some((selected) => selected.key === item.key));

      setSelectedRowKeys([...selectedRowKeys, ...newSelectedRows]);
      setIsSelectAllChecked(true);
    } else {
      setSelectedRowKeys(
        selectedRowKeys.filter((item) => !dataSource.some((d) => d.key === item.key))
      );
      setIsSelectAllChecked(false);
    }
  };

  const handleSelectRow = (checked: boolean, key: string, status: string, sessionId: string) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, { key, status, sessionId }]);
    } else {
      setSelectedRowKeys(selectedRowKeys.filter((item) => item.key !== key));
    }
  };
  
  

  const renderStatusOptions = ( PassedCopilotName:string | undefined) => {
    switch (PassedCopilotName) {
      case COPILOTS_NAME.PCP:
        return (
          <>
            <Option value="">ALL</Option>
            <Option value="Fetching">FETCHING</Option>
            <Option value="Failed">FAILED</Option>
            <Option value="Succeeded">SUCCEEDED</Option>
            <Option value="Processing">PROCESSING</Option>
          </>
        );
      case COPILOTS_NAME.HEDIS_INSIGHTS:
        return (
          <>
            <Option value="">ALL</Option>
            <Option value="FAILED">FAILED</Option>
            <Option value="ON_HOLD">ON HOLD</Option>
            <Option value="PROCESSING-PAGES">PROCESSING-PAGES</Option>
            <Option value="COMPLETED-WITH-ERRORS">COMPLETED-WITH-ERRORS</Option>
            <Option value="COMPLETED">COMPLETED</Option>
            <Option value="LOADING-FILE">LOADING-FILE</Option>
          </>
        );
      default:
        return (
          <>
            <Option value="">ALL</Option>
            <Option value="ERROR">FAILED</Option>
            <Option value="ON_HOLD">ON HOLD</Option>
            <Option value="PROCESSING">PROCESSING</Option>
          </>
        );
    }
  };

  const formatStatus = (status: string) => {
    return status
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderStatus = (status: string, type: 'SESSION' | 'SEARCH') => (
    <div className="status" style={{ color: getClaimsStatusClass(status, type) }}>
      <p style={{ color: getClaimsStatusClass(status, type) }}>{formatStatus(status)}</p>
    </div>
  );

  
  const getColumns = (): ColumnsType<ISearchDataType> => {
    const columns = [
     
      {
        title: "Claim ID",
        dataIndex: "claimId",
        key: "claimId",
        className: "text-wrap-class",
        sorter: true,
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        className: "text-wrap-class",
      },
      {
        title: "Session Status",
        dataIndex: "sessionStatus",
        key: "sessionStatus",
        className: "text-wrap-class",
        render: (text: string) => renderStatus(text, 'SESSION'),
      },
      {
        title: "Search Status",
        dataIndex: "searchStatus",
        key: "searchStatus",
        className: "text-wrap-class",
        render: (text: string) => renderStatus(text, 'SEARCH'),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        className: "text-wrap-class",
        render: (text: string) => new Date(text).toLocaleString(),
        sorter: true,
      },
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        key: "updatedAt",
        className: "text-wrap-class",
        render: (text: string) => new Date(text).toLocaleString(),
        sorter: true,
      },
    ];
  
    return columns.filter((column) => column !== null) as ColumnsType<ISearchDataType>;
  };
  
  

  useEffect(() => {
    // setSelectedRowKeys([]);
    setIsSelectAllChecked(false);
  }, [page, pageSize]);

  useEffect(() => {
    fetchClaimsSearches();
  }, [fetchClaimsSearches, selectedFromDate, selectedToDate, searchTerm, sort]);

  const onRangeChange = (dates: RangeValue<Moment>, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: defaultFromDate?.toString() ?? null,
          toDate: defaultToDate?.toString() ?? null,
        })
      );
    }
  };

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  const expandedRowRender = (record: ISearchDataType) => {
    return (
      <div className="flex gp-40 expanded-row-content">
        {/* Session ID */}
        <div className="session-id">
          <p>{record.sessionId}</p>
        </div>
  
        <div className="status-description">
          <p>{record.statusDescription}</p>
        </div>
  
        <div className="json-content">
          <EvidenceContentJSON pageInfo={JSON.parse(record.resJSON)} collapsed={true} />
        </div>
      </div>
    );
  };
  
  

  const CustomExpandIcon = ({ expanded, onExpand, record }: any) => {
    const iconStyle = { fontSize: "20px", cursor: "pointer" };
    if (expanded) {
      return (
        <Button style={{ padding: "5px" }} onClick={(e) => onExpand(record, e)}>
          <MinusOutlined style={iconStyle} />
        </Button>
      );
    }
    return (
      <Button style={{ padding: "5px" }} onClick={(e) => onExpand(record, e)}>
        <PlusOutlined style={iconStyle} />
      </Button>
    );
  };


  
  return (
    <div>
      <div style={{ display: "flex", marginBottom: "2em" }} className="tabHeader">
        <RangePicker
          allowClear={false}
          defaultValue={[
            selectedFromDate ? moment(selectedFromDate) : null,
            selectedToDate ? moment(selectedToDate) : null,
          ]}
          presets={rangePresets}
          onChange={onRangeChange}
          className="range-picker"
          disabledDate={(current) => {
            return current && current.valueOf() > moment().endOf("day").valueOf();
          }}
          disabled={!!(searchTerm || searchDocumentUUID)}
        />
        <div className="tabHeader-delete">
          {selectedRowKeys.length ? (
            <p className="tabHeader-selected">{`${selectedRowKeys.length} ${
              selectedRowKeys.length === 1 ? "item" : "items"
            } selected`}</p>
          ) : (
            <></>
          )}
          <AppPagination
            className="tr m-b paginationDiv"
            {...{
              showSizeChanger: true,
              current: page,
              total: totalDocuments,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange,
            }}
          />
        </div>
      </div>
      <Table
        className="document-container-table"
        columns={getColumns()}
        dataSource={dataSource}
        onChange={onTableChange}
        loading={isDocumentsLoading}
        pagination={false}
        expandable={{
          expandedRowRender,
          expandIconColumnIndex: 9,
          columnTitle: "Detailed Logs",
          onExpand: handleExpand,
          expandIcon: CustomExpandIcon,
        }}
        locale={{ emptyText: <Empty>No Searches Found</Empty> }}
      />
    </div>
  );
};

export default SearchLogs;
