import React, { useState } from "react";
import { Modal, Upload, Checkbox, Button, message, Tag } from "antd";
import { InboxOutlined, CloseOutlined, FileExcelOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/es/upload";
import "../modal.scss";
import { openNotificationWithIcon } from "../../../utils";
import { setIsFileImported } from "../../../store";
import { useDispatch } from "react-redux";

const { Dragger } = Upload;

interface FileUploadModalProps {
  visibility: boolean;
  handleClose: () => void;
  handleUpload?: (filePath: string, cleanExistingData: boolean, file: File) => Promise<void>;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ 
  visibility, 
  handleClose, 
  handleUpload 
}) => {
  const [cleanExistingData, setCleanExistingData] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [filePath, setFilePath] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const dispatch = useDispatch();

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    accept: '.csv,text/csv,application/vnd.ms-excel,application/csv',
    beforeUpload: (file) => {
      const isCSV = file.type === 'text/csv' || 
      file.name.toLowerCase().endsWith('.csv') ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/csv';

      if (!isCSV) {
        message.error(`${file.name} is not a CSV file`);
        return Upload.LIST_IGNORE;
      }
      
      setFilePath(file.name);
      setFileToUpload(file);
      return false; 
    },
    onChange(info) {
      const files = [...info.fileList];
      setFileList(files);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleModalClose = () => {
    setFileList([]);
    setFilePath('');
    setFileToUpload(null);
    setLoading(false);
    handleClose();
  };

  const handleConfirmUpload = async () => {
    if (handleUpload && filePath && fileToUpload) {
      setLoading(true);
      try {
        const res: any = await handleUpload(filePath, cleanExistingData, fileToUpload);
        const { processedRecords} = res.data;

        const notificationMessage = `Successfully inserted ${processedRecords} entries.`;
        openNotificationWithIcon("Import Successful",notificationMessage, "success");
        dispatch(setIsFileImported(true));
        handleModalClose();
      } catch (error: any) {
        openNotificationWithIcon("Failed to import file", error.response.data.message, "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="CSV File Upload"
      open={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleModalClose} />}
    >
      <h1 className="m-b">Upload CSV File</h1>
      <div className="file-upload-content flex flex-column gp">
        <Dragger 
          {...uploadProps} 
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <FileExcelOutlined />
          </p>
          <p className="ant-upload-text">Click or drag CSV file to this area to upload</p>
          <p className="ant-upload-hint">
            Only CSV files are supported. Strictly prohibited from uploading sensitive or unauthorized files.
          </p>
        </Dragger>

        <div className="flex">
          <Tag bordered={false} color="warning" className="flex tag-container">
            <strong>Note:</strong>&nbsp; 
            Enabling this option will <strong>&nbsp;delete all existing entries</strong>&nbsp;from the table before importing the new CSV file. 
            If unchecked, the new data will be&nbsp;<strong>appended</strong>&nbsp; 
            to the existing records.
          </Tag>
        </div>
        
        <div className="">
          <Checkbox 
            checked={cleanExistingData}
            onChange={(e) => setCleanExistingData(e.target.checked)}
          >
            Clean existing data before uploading
          </Checkbox>
        </div>
        
        <div className="flex jcc gp">
          <Button type="primary" className="outline" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button 
            type="primary" 
            className="fill"
            disabled={fileList.length === 0} 
            loading={loading} 
            onClick={handleConfirmUpload}
          >
            {loading ? 'Uploading...' : 'Confirm Upload'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadModal;