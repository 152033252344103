import { useCallback, useEffect, useState } from "react";
import { usePagination } from "./usePagination";
import { SorterResult } from "antd/es/table/interface";
import { STATUS_CODE, getOffsetFromPageAndPageSize, openNotificationWithIcon } from "../utils";
import { documentService } from "../api/document";
import moment from "moment";
import { COPILOTS_NAME } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setIsFileImported, setSelectedDateRange } from "../store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { CLAIMS_DOCUMENTS } from "../containers/copilot-details/copilots-details-container/constants";
import { searchAnalysisServices } from "../api/search-analysis";
import { ClaimsService } from "../api/claims";

interface ColumnSort extends SorterResult<any> {
  sortOrder: "asc" | "desc" | null;
}
export interface ISearchDataType {
  id: string;              
  claimId: string;           
  state: string;             
  userId: string;            
  sessionStatus: string;    
  searchStatus: string;      
  statusDescription: string; 
  createdAt: string;         
  updatedAt: string;        
  status: string;            
  sessionId: string;         
  resJSON: string;           
}

interface DateFormattedState {
  fromDateFormatted: string | null;
  toDateFormatted: string | null;
}

export const useFetchDocuments = (copilotName?: string) => {
  const { page, onPaginationChange, pageSize, pageSizeOptions } = usePagination();
  const dispatch = useDispatch();
  const [sort, setSort] = useState({} as ColumnSort);
  const [sortBy, setSortBy] = useState("-createdAt");
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([] as IDocument[]);
  const [extractionData, setExtractionData] = useState({} as IExtractionData);
  const [total, setTotal] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(true);
  // const defaultFromDate = moment("2023-01-01", "YYYY-MM-DD") ;
  // const defaultToDate = moment();
  const defaultFromDate = moment().add(-1, "d");
  const defaultToDate = moment();
  const defaultOverviewFromDate = moment().add(-1, "d");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [overviewFromDate, setOverviewFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDocumentUUID, setSearchDocumentUUID] = useState("");
  const [searchTransactionId,setSearchTransactionId] = useState("");
  const [status, setStatus] = useState("");
  const [keywords, setKeywords] = useState([] as Record<string, string>[]);
  const [hedisExtractionData, setHedisExtractionData] = useState({} as any);
  const [ searches, setSearches ] = useState<ISearchDataType[]>([]);
  const [ claimsData, setClaimsData ] = useState([])
  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );
  const isFileImported = useSelector((state: RootState) => state.ui.isFileImported);

  const fromDateFormatted = selectedFromDate
    ? moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
    : null;
  const toDateFormatted = selectedToDate
    ? moment(selectedToDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
    : null;

  if (!selectedFromDate || !selectedToDate)
    dispatch(
      setSelectedDateRange({
        fromDate: defaultFromDate.toString(),
        toDate: defaultToDate.toString(),
      })
    );

  // const [copilot, setCopilot] = useState(copilotName && getCopilotName())

  const getCopilotName = () => {
    switch (copilotName) {
      case COPILOTS_NAME.PEGA_PRIOR_AUTH:
        return "pega";
      case COPILOTS_NAME.HEDIS_INSIGHTS:
        return "hedis";
      case COPILOTS_NAME.ANG_INSIGHTS:
        return "ANG";
      case COPILOTS_NAME.CCA:
        return "CCA";
      case COPILOTS_NAME.PCP:
        return "PCP";
      case COPILOTS_NAME.CLAIMS:
        return "Claims"
      case COPILOTS_NAME.UM:
        return "UM"
    }
  };

  const onSortChange = (newSort: any) => {
    if (newSort.order === "ascend") setSort({ ...newSort, sortOrder: "asc" });
    else if (newSort.order === "descend") setSort({ ...newSort, sortOrder: "desc" });
    else setSort({ ...newSort, sortOrder: null });
  };

  const getDocumentsFilterObject = useCallback(() => {
    let obj: IGetDocumentsRequest = searchTerm || searchDocumentUUID?{
      limit: pageSize,
      offset: searchTerm || searchDocumentUUID ? 0 : getOffsetFromPageAndPageSize(page, pageSize),
      searchTerm: searchTerm,
      searchDocumentUUID: searchDocumentUUID,
      transactionId: searchTransactionId,
      copilot: getCopilotName(),
      sortBy: sortBy,
      status: status,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    } :{
      limit: pageSize,
      offset: searchTerm || searchDocumentUUID ? 0 : getOffsetFromPageAndPageSize(page, pageSize),
      fromDate: fromDateFormatted,
      toDate: toDateFormatted,
      searchTerm: searchTerm,
      searchDocumentUUID: searchDocumentUUID,
      transactionId: searchTransactionId,
      copilot: getCopilotName(),
      sortBy: sortBy,
      status: status,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (sort.sortOrder && sort.field) {
      obj = { ...obj, sortField: sort.field.toString(), sortType: sort.sortOrder };
    }
    return obj;
  }, [
    page,
    pageSize,
    sort.field,
    sort.sortOrder,
    selectedFromDate,
    selectedToDate,
    searchTerm,
    searchDocumentUUID,
    searchTransactionId,
    getCopilotName(),
    sortBy,
    status,
  ]);

  const getRequestsFilterObject = useCallback(() => {
    let obj: IGetDocumentsRequest = searchTerm
      ? {
          limit: pageSize,
          offset: getOffsetFromPageAndPageSize(page, pageSize),
          searchTerm: searchTerm,
          copilot: getCopilotName(),
          sortBy: sortBy,
          status: status,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      : {
          limit: pageSize,
          offset: getOffsetFromPageAndPageSize(page, pageSize),
          fromDate: fromDateFormatted,
          toDate: toDateFormatted,
          searchTerm: searchTerm,
          copilot: getCopilotName(),
          sortBy: sortBy,
          status: status,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

    if (sort.sortOrder && sort.field) {
      obj = { ...obj, sortField: sort.field.toString(), sortType: sort.sortOrder };
    }
    return obj;
  }, [
    page,
    pageSize,
    sort.field,
    sort.sortOrder,
    selectedFromDate,
    selectedToDate,
    searchTerm,
    searchDocumentUUID,
    getCopilotName(),
    sortBy,
    status,
    sort
  ]);

  const getExtractionDataFilterObject = useCallback(() => {
    let obj: IGetDocumentsRequest = {
      limit: null,
      offset: getOffsetFromPageAndPageSize(page, pageSize),
      fromDate: fromDateFormatted,
      toDate: toDateFormatted,
      copilot: getCopilotName(),
      overview: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (sort.sortOrder && sort.field) {
      obj = { ...obj, sortField: sort.field.toString(), sortType: sort.sortOrder };
    }
    return obj;
  }, [selectedFromDate, selectedToDate]);

  const fetchDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await documentService.getDocuments(getDocumentsFilterObject());
      const pagination = data.pagination || { totalCount: 0 };
      
      const updatedDoc = Array.isArray(data.data)
        ? data.data.map((doc: any) => ({
            ...doc,
            DocumentUUID: doc.DocumentUUID?.toLowerCase() || '',
          }))
        : [];
  
      setDocuments(updatedDoc as IDocument[]);
      setTotal(pagination.totalCount ? pagination.totalCount : 0);
    } catch (err: any) {
      if (err?.response?.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        console.log(err);
        openNotificationWithIcon("", err?.response?.data?.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [getDocumentsFilterObject]);

  const fetchPcpDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      let data, pagination; 
      if (getCopilotName() === COPILOTS_NAME.CLAIMS) {
        ({ data, pagination } = CLAIMS_DOCUMENTS);
      } else {
        const response = await documentService.getDocuments(getDocumentsFilterObject());
        data = response.data;
        pagination = response.pagination;
      }
      // const { data,pagination } = await documentService.getDocuments(getDocumentsFilterObject());
      //converting all the uuid to lowercase
      const updatedDoc = data
        ? data.map((doc: any) => ({
            ...doc,
            DocumentUUID: doc.DocumentUUID.toLowerCase(),
            createdAt: doc.CreatedAt,
            updatedAt: doc.UpdatedAt,
            executionTime: doc.ExecutionTime
          }))
        : [];
      setDocuments(updatedDoc as IDocument[]);
      setTotal(pagination.totalCount ? pagination.totalCount : 0);
    } catch (err: any) {
      if (err?.response?.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        console.log(err)
        openNotificationWithIcon("", err?.response?.data?.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [getDocumentsFilterObject]);


  const fetchRequests = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await documentService.getDocuments(getRequestsFilterObject());
      const updatedDoc = data.data.map((doc: any) => ({
        ...doc,
        id: doc.id,
      }));
      setDocuments(updatedDoc as IDocument[]);
      setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [getRequestsFilterObject]);

  const fetchExtractionData = useCallback(
    async (extractionFromDate: string, extractionToDate: string, type?: string) => {
      setIsLoading(true);
      try {
        /*if (copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH) {
          const pegaExtractionData = await fetchPegaExtractionData();
          setExtractionData(pegaExtractionData);
         
        } else*/ if (copilotName === COPILOTS_NAME.HEDIS_INSIGHTS) {
          const { data } = await documentService.getHedisExtractionData(
            extractionFromDate,
            extractionToDate,
            type
          );
          const overviewData = await documentService.getHedisCopilotExtractionData(
            extractionFromDate,
            extractionToDate,
            type
          );
          setHedisExtractionData({ ...data.data, ...overviewData.data });
        } 
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setIsLoading(false);
      }
    },
    [getExtractionDataFilterObject]
  );

  const fetchKeywords = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await documentService.getFields();
      setKeywords(data.data);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchClaimsSearches = useCallback(async () => {
    setIsLoading(true)
    try{
      const { data } = await searchAnalysisServices.getSearchesData(getDocumentsFilterObject())
      setSearches(data.data);
      setTotal(data.totalCount);
    }catch(err: any){
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    }finally{
      setIsLoading(false)
    }
  }, [getDocumentsFilterObject])

  const fetchClaimsData = useCallback(async () => {
    setIsLoading(true)
    try{

      const { data } = await ClaimsService.getClaimsData(pageSize,getOffsetFromPageAndPageSize(page, pageSize))
      setClaimsData(data.data);
      setTotal(data.totalCount);
    }catch(err: any){
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    }finally{
      dispatch(setIsFileImported(false));
      setIsLoading(false)
    }
    
  }, [pageSize, isFileImported, getOffsetFromPageAndPageSize(page, pageSize)])
  

  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    getDocumentsFilterObject,
    fetchDocuments,
    isDocumentsLoading: isLoading,
    setIsDocumentsLoading: setIsLoading,
    documents,
    totalDocuments: total,
    setDocuments,
    setTotalDocuments: setTotal,
    isAuthorized,
    defaultFromDate,
    defaultToDate,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    searchTerm,
    setSearchTerm,
    searchDocumentUUID,
    setSearchDocumentUUID,
    setSearchTransactionId,
    searchTransactionId,
    setSortBy,
    fetchExtractionData,
    extractionData,
    setStatus,
    fetchKeywords,
    field: keywords,
    defaultOverviewFromDate,
    overviewFromDate,
    setOverviewFromDate,
    hedisExtractionData,
    fetchRequests,
    fetchPcpDocuments,
    fetchClaimsSearches,
    searches,
    fetchClaimsData,
    claimsData
  };
};
