import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

interface IModal {
  [name: string]: boolean;
}

interface IDateRange {
  fromDate: string | null; toDate: string | null;
}

interface UiSlice {
  modal: IModal;
  isDeleteConfirmed: boolean;
  selectedDateRange: IDateRange;
  isFileImported: boolean;
  isChanged:boolean;
  data: any
  tempData: {[key:string]:string}
}

const initialFromDate = moment().subtract(1, "days").format("YYYY-MM-DD");
const initialToDate = moment().format("YYYY-MM-DD");

export const uiInitialState: UiSlice = {
  modal: {} as IModal,
  isDeleteConfirmed: false,
  selectedDateRange: { fromDate: initialFromDate, toDate: initialToDate },
  isFileImported: false,
  isChanged:false,
  data: null,
  tempData: {totalDocsWithData:"",totalSrfPageFound:""}
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: uiInitialState,
  reducers: {
    openModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = true;
    },
    closeModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = false;
    },
    updateDeleteConfirmation: (state: UiSlice, { payload }: PayloadAction<boolean>) => {
      state.isDeleteConfirmed = payload;
    },
    setSelectedDateRange: (state: UiSlice, { payload }: PayloadAction<IDateRange>) => {
      state.selectedDateRange = payload;
    },
    clearSelectedDateRange: (state: UiSlice) => {
      state.selectedDateRange = { fromDate: "", toDate: "" };
    },
    setIsFileImported: (state: UiSlice, { payload }: PayloadAction<boolean>) => {
      state.isFileImported = payload;
    },
    setIsChanged: (state: UiSlice, { payload }: PayloadAction<boolean>) => {
      state.isChanged = payload;
    },
    setModalData: (state: UiSlice, { payload }: PayloadAction<any>) => {
      state.data = payload;
    },
    clearModalData: (state: UiSlice) => { 
      state.data = null;
    },
    setTempData: (state: UiSlice, { payload }: PayloadAction<any>) => {
      state.tempData = payload;
    }
  },
});

export const {
  openModal,
  closeModal,
  updateDeleteConfirmation,
  setSelectedDateRange,
  clearSelectedDateRange,
  setIsFileImported,
  setIsChanged,
  setModalData,
  clearModalData,
  setTempData
} = uiSlice.actions;

export default uiSlice.reducer;
